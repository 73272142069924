.blogLayout{
    background-image: url('../../assets/homepage/paperImg.jpg');
    height: 100vh;
}
.bricks-wrapper .entry {
	margin-bottom: 40px;
}

.bricks-wrapper .entry-thumb,
.bricks-wrapper .entry-text {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}

.bricks-wrapper .entry-thumb {
	position: relative;
	overflow: hidden;
}

.bricks-wrapper .entry-thumb .thumb-link img {
	vertical-align: bottom;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.bricks-wrapper .entry-thumb .thumb-link::before {
	content: "";
	display: block;
	background: rgba(0, 0, 0, 0.8);
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	z-index: 1;
}

.bricks-wrapper .entry-thumb .thumb-link::after {
	content: "...";
	font-family: georgia, serif;
	font-size: 4.5rem;
	z-index: 1;
	display: block;
	height: 90px;
	width: 90px;
	letter-spacing: -2.6px;
	line-height: 90px;
	margin-left: -45px;
	margin-top: -45px;
	position: absolute;
	left: 50%;
	top: 50%;
	text-align: center;
	color: #FFFFFF;
	opacity: 0;
	visibility: hidden;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-transform: scale(0.5);
	-ms-transform: scale(0.5);
	transform: scale(0.5);
}

.bricks-wrapper .entry-thumb:hover .thumb-link::before {
	opacity: 1;
	visibility: visible;
}

.bricks-wrapper .entry-thumb:hover .thumb-link::after {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.bricks-wrapper .entry-thumb:hover .thumb-link img {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.bricks-wrapper .entry-text {
	padding: 1.8rem 2.8rem 3.6rem;
	background-color: white;
}

.bricks-wrapper .entry-header .entry-title {
	font-size: 2.5rem;
	line-height: 1.32;
	margin-bottom: 1.8rem;	
}
.bricks-wrapper .entry-header .entry-title a,
.bricks-wrapper .entry-header .entry-title a:visited {
	color: #000000;
}

.bricks-wrapper .entry-header .entry-meta {
	font-family: "muli-regular", sans-serif;
	font-size: 1.5rem;
	margin-bottom: 1.2rem;
}
.bricks-wrapper .entry-header .entry-meta a,
.bricks-wrapper .entry-header .entry-meta a:visited {
	color: #000000;
}

.bricks-wrapper .entry-header .cat-links a::after {
	content: ", ";
}

.bricks-wrapper .entry-header .cat-links a:last-child::after {
	display: none;
}

.bricks-wrapper .entry-excerpt {
	font-size: 1.5rem;
	line-height: 2.7rem;
	color: #999999;
}


/* featured post slider - (_common-styles.scss) 
 * ------------------------------------------------------------------- */
#featured-post-slider {
	position: relative;
}

.featured-grid,
.featured-post-slide {
	height: 600px;
}

.featured-post-slide {
	position: relative;
	background-color: #FFFFFF;
	width: 100%;
	padding: 6rem 15% 3rem 15%;
	text-align: center;
	display: table;
}

.featured-post-slide .post-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.featured-post-slide .overlay {
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.featured-post-slide:hover .overlay {
	background-color: rgba(0, 0, 0, 0.5);
}

.featured-post-slide .post-content {
	display: table-cell;
	position: relative;
	vertical-align: middle;
	z-index: 500;
}

.featured-post-slide .entry-meta {
	font-family: "muli-regular", sans-serif;
	margin: -3rem 0 0.9rem 0;
	color: rgba(255, 255, 255, 0.6);
	line-height: 1.412;
}

.featured-post-slide .entry-meta li {
	display: inline-block;
	margin: 0 3px;
	padding: 0;
}

.featured-post-slide .entry-meta li a {
	color: rgba(255, 255, 255, 0.6);
}

.featured-post-slide .entry-meta li a:hover,
.featured-post-slide .entry-meta li a:focus {
	color: white;
	border-color: rgba(255, 255, 255, 0.2);
}

.featured-post-slide .slide-title {
	font-family: "montserrat-bold", sans-serif;
	font-size: 3.8rem;
	line-height: 1.421;
	margin-bottom: 3rem;
	color: #FFFFFF;
}

.featured-post-slide .slide-title a {
	color: #FFFFFF;
}

.featured-post-slide .slide-title a:hover,
.featured-post-slide .slide-title a:focus {
	color: white;
	border-color: rgba(255, 255, 255, 0.2);
}

/* featured-post-slider direction nav
 * --------------------------------------------------------- */
#featured-post-slider .flex-direction-nav li {
	position: absolute;
	top: 50%;
	padding: 0;
	margin-top: -30px;
	height: 60px;
	width: 60px;
	z-index: 500;
}

#featured-post-slider .flex-direction-nav li a {
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
	display: block;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 20px 16px;
	background-color: black;
	opacity: .7;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}



#featured-post-slider .flex-direction-nav li a:hover {
	opacity: 1;
}

#featured-post-slider .flex-direction-nav li.flex-nav-prev {
	left: 0;
}

#featured-post-slider .flex-direction-nav li.flex-nav-next {
	right: 0;
}

/* responsive:
/* featured slider
/* ------------------------------------------------------------------- */
@media only screen and (max-width:1024px) {
	.featured-post-slide .slide-title {
		font-size: 3.7rem;
	}

}

@media only screen and (max-width:768px) {
	.featured-post-slide {
		padding: 6rem 10% 3rem 10%;
	}

	.featured-post-slide .entry-meta {
		font-size: 1.5rem;
	}

	.featured-post-slide .slide-title {
		font-size: 3.2rem;
	}

}

@media only screen and (max-width:600px) {
	.featured-grid,
	.featured-post-slide {
		height: 558px;
	}

	.featured-post-slide .slide-title {
		font-size: 3rem;
	}

	#featured-post-slider .flex-direction-nav li {
		top: auto;
		margin-top: auto;
		bottom: 0;
	}

}

@media only screen and (max-width:400px) {
	.featured-grid,
	.featured-post-slide {
		height: 450px;
	}

	.featured-post-slide .slide-title {
		font-size: 2.7rem;
	}

}

 
/*  post formats - masonry view - (_common-styles.scss) 
 * ------------------------------------------------------------------- */

/* format quote & format link
 * --------------------------------------- */
.format-quote,
.format-link {
	text-align: center;
}

.format-quote .entry-thumb,
.format-link .entry-thumb {
	display: table;
	background: #FFFFFF;
	padding: 1.8rem 2.8rem 3rem;
	min-height: 324px;
	width: 100%;
	position: relative;
}

.format-quote .entry-thumb::before,
.format-link .entry-thumb::before {
	content: "\e920";
	font-family: "icomoon";
	font-size: 3rem;
	color: #ccc;
	position: absolute;
	top: 3.3rem;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.format-quote blockquote::before {
	display: none;
}

.format-quote blockquote,
.format-link .link-wrap {
	display: table-cell;
	margin: 0;
	padding-left: 0;
	vertical-align: middle;
}

.format-quote blockquote p,
.format-link .link-wrap p {
	font-family: "montserrat-bold", sans-serif;
	padding: 6rem 0 0 0;
	font-size: 2.2rem;
	line-height: 1.636;
}

.format-quote cite,
.format-link cite {
	display: block;
	font-family: "muli-regular", sans-serif;
	font-size: 1.5rem;
	font-style: normal;
	line-height: 1.4;
	color: #999999;
}

.format-quote cite::before,
.format-link cite::before {
	display: none;
}

.format-quote cite a,
.format-quote cite a:visited {
	color: #999999;
	border: none;
}

.format-link .entry-thumb::before {
	content: "\e96b";
	font-size: 3.6rem;
	color: #CCCCCC;
}

.format-link .link-wrap cite:before {
	display: none;
}

.format-link .link-wrap cite a,
.format-link .link-wrap cite a:visited {
	color: #999999;
	display: inline-block;
	padding-bottom: .3rem;
}

.format-link .link-wrap cite a:hover,
.format-link .link-wrap cite a:focus {
	color: #000000;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/* format video
 * --------------------------------------- */
.format-video .entry-thumb a {
	cursor: zoom-in;
	display: block;
	position: relative;
}

.format-video .entry-thumb a img {
	vertical-align: bottom;
}

.format-video .entry-thumb a::before {
	content: "\e998";
	position: absolute;
	top: 50%;
	left: 50%;
	font-family: "icomoon";
	font-size: 8rem;
	border-radius: 50%;
	color: white;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	cursor: zoom-in;
}


/* ===================================================================
 *  09. header styles - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
header {
	width: 100%;
	background-size: 46px 29px;
	height: 130px;
	border-bottom: 1px solid #E5E5E5;
}
header .header-content {
	height: auto;
	max-width: 1290px;
	position: absolute;
	top: 24px;
	left: 0;
	right: 0;
	width: 100%;
}
header a {
	border: none;
}

@media only screen and (max-width:400px) {
	header {
		height: 118px;
	}

}

/* ------------------------------------------------------------------- 
 * header logo - (_layout.css) 
 * ------------------------------------------------------------------- */
header .logo {
	position: absolute;
	left: 35px;
	top: 6px;
	z-index: 601;
}

header .logo a {
	display: block;
	margin: 0;
	padding: 0;
	outline: 0;
	border: none;
	width: 60px;
	height: 60px;
	background-size: 60px 60px;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

/* responsive:
 * header logo
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	header .logo {
		left: 45px;
	}

}

@media only screen and (max-width:600px) {
	header .logo {
		left: 35px;
	}

}

@media only screen and (max-width:400px) {
	header .logo {
		left: 25px;
	}

	header .logo a {
		width: 54px;
		height: 54px;
		background-size: 54px 54px;
	}

}

/* ------------------------------------------------------------------- 
 * navigation - (_layout.css) 
 * ------------------------------------------------------------------- */
.triggers {
	position: absolute;
	right: 35px;
	top: 18px;
}

.triggers .search-trigger {
	display: inline-block;
	font-size: 17px;
	color: #000000;
}

.triggers .menu-toggle {
	display: none;
}

/* responsive:
 * triggers
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	.triggers {
		right: 40px;
		top: 12px;
	}

}

@media only screen and (max-width:600px) {
	.triggers {
		right: 30px;
	}

}

@media only screen and (max-width:400px) {
	.triggers {
		right: 20px;
	}

}

/* ------------------------------------------------------------------- 
 * main navigation - (_layout.css) 
 * ------------------------------------------------------------------- */
#main-nav-wrap {
	display: table;
	float: right;
	padding-right: 85px;
	font-family: "montserrat-regular", sans-serif;
	font-size: 15px;
	height: 78px;
}

.main-navigation {
	display: table-cell;
	vertical-align: middle;
	list-style: none;
}

.main-navigation li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 22px;
	padding: 0;
}

.main-navigation li:last-child {
	margin-right: 0;
}

.main-navigation li a {
	display: block;
	padding-bottom: 9px;
	color: #898989;
	border: none;
}

.main-navigation li a:hover {
	color: #000000;
}

.main-navigation li > a {
	border-bottom: 2px solid transparent;
}

.main-navigation li.current > a {
	border-bottom: 2px solid #151515;
	color: #000000;
	font-family: "montserrat-bold", sans-serif;
}

.main-navigation ul {
	position: absolute;
	top: 100%;
	left: 0;
	margin: 0;
	background: #f4f4f4;
	border-radius: 0 0 3px 3px;
	font-size: 11px;
	z-index: 500;
	display: none;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.main-navigation ul ul {
	position: absolute;
	top: 0;
	left: 100%;
	left: calc(100% + 1px);
	border-radius: 0 3px 3px 3px;
}

.main-navigation ul li {
	display: block;
	text-align: left;
	padding: 0;
	margin: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	min-height: 48px;
	width: 100%;
}

.main-navigation ul li:last-child {
	border: none;
}

.main-navigation ul li a {
	white-space: nowrap;
	padding: 16.5px 25px;
	font-family: "muli-regular", sans-serif;
	font-size: 14px;
	line-height: 15px;
	display: block;
	color: #8E8E8E;
}

.main-navigation ul li a:hover {
	background: transparent;
	color: #000000;
}

.main-navigation ul li:hover > ul,
.main-navigation ul li.sfHover > ul {
	display: block;
}

/* -------------------------------------------------------------------
 * main navigation - mobile view
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	.triggers {
		z-index: 601;
	}

	.triggers .menu-toggle {
		display: block;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50%;
		right: 0;
	}

	.triggers .search-trigger {
		position: absolute;
		top: 50%;
		right: 45px;
		font-size: 21px;
		margin-top: 5px;
		display: block;
		height: 3rem;
		line-height: 3rem;
		width: 2.6rem;
		text-align: left;
	}

	#main-nav-wrap {
		display: block;
		width: 100%;
		float: none;
		position: absolute;
		margin: 0;
		padding: 0;
		top: -24px;
		right: 0;
		z-index: 600;
	}

	.menu-toggle span {
		display: block;
		width: 24px;
		height: 3px;
		margin-top: -1.5px;
		position: absolute;
		right: 8px;
		top: 50%;
		bottom: auto;
		left: auto;
		background-color: black;
		-moz-transition: background 0.2s ease-in-out;
		-o-transition: background 0.2s ease-in-out;
		-webkit-transition: background 0.2s ease-in-out;
		-ms-transition: background 0.2s ease-in-out;
		transition: background 0.2s ease-in-out;
		font: 0/0 a;
		text-shadow: none;
		color: transparent;
	}

	.menu-toggle span::before,
	.menu-toggle span::after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: inherit;
		left: 0;
		-moz-transition-duration: 0.2s, 0.2s;
		-o-transition-duration: 0.2s, 0.2s;
		-webkit-transition-duration: 0.2s, 0.2s;
		-ms-transition-duration: 0.2s, 0.2s;
		transition-duration: 0.2s, 0.2s;
		-moz-transition-delay: 0.2s, 0s;
		-o-transition-delay: 0.2s, 0s;
		-webkit-transition-delay: 0.2s, 0s;
		-ms-transition-delay: 0.2s, 0s;
		transition-delay: 0.2s, 0s;
	}

	.menu-toggle span::before {
		top: -8px;
		-moz-transition-property: top, transform;
		-o-transition-property: top, transform;
		-webkit-transition-property: top, transform;
		-ms-transition-property: top, transform;
		transition-property: top, transform;
	}

	.menu-toggle span::after {
		bottom: -8px;
		-moz-transition-property: bottom, transform;
		-o-transition-property: bottom, transform;
		-webkit-transition-property: bottom, transform;
		-ms-transition-property: bottom, transform;
		transition-property: bottom, transform;
	}

	.menu-toggle.is-clicked span {
		background-color: transparent;
	}

	.menu-toggle.is-clicked span::before,
	.menu-toggle.is-clicked span::after {
		background-color: black;
		-moz-transition-delay: 0s, 0.2s;
		-o-transition-delay: 0s, 0.2s;
		-webkit-transition-delay: 0s, 0.2s;
		-ms-transition-delay: 0s, 0.2s;
		transition-delay: 0s, 0.2s;
	}

	.menu-toggle.is-clicked span::before {
		top: 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.menu-toggle.is-clicked span::after {
		bottom: 0;
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	.main-navigation {
		display: none;
		background: #FFFFFF;
		padding: 132px 45px 54px 45px;
		margin: 0;
		width: 100%;
		height: auto;
		clear: both;
		box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
	}

	.main-navigation li {
		float: none;
	}

	.main-navigation > li {
		display: block;
		text-align: left;
		margin-right: 0;
		border: none;
		min-height: 60px;
	}

	.main-navigation li a {
		display: block;
		width: auto;
		color: #848484;
		padding: 18px 0;
		line-height: 24px;
		border: none;
	}

	.main-navigation li a:hover {
		color: #000000;
		border: none;
	}

	.main-navigation li.current > a {
		color: #000000;
		border: none;
	}

	.main-navigation li.has-children a {
		position: relative;
	}

	.main-navigation li.has-children > a:after {
		content: "\f067";
		display: inline;
		padding-left: 10px;
		color: #000000;
		font-family: "fontAwesome";
		font-size: 12px;
		height: 20px;
		width: 20px;
		line-height: 20px;
		margin-top: -10px;
		position: absolute;
		left: auto;
		right: 0;
		top: 50%;
		background: none;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.main-navigation li.has-children:hover > a:after {
		content: "\f068";
	}

	.main-navigation li.has-children ul {
		position: static;
		background: #FFFFFF;
		box-shadow: none;
		margin-top: -6px;
	}

	.main-navigation li.has-children ul ul {
		margin-bottom: 0;
	}

	.main-navigation li.has-children li {
		border: none;
		padding-left: 12px;
		margin: 0;
		padding-left: 12px;
		min-height: 48px;
	}

	.main-navigation li.has-children li a {
		padding: 18px 25px 18px 0;
	}

	.main-navigation li.has-children li::last-child {
		margin-bottom: 18px;
	}

}

/* make sure the menu is visible on larger screens
 * ------------------------------------------------------------------- */
@media only screen and (min-width:769px) {
	#main-nav-wrap ul.main-navigation {
		display: table-cell !important;
	}

}

/* ------------------------------------------------------------------- 
 * search wrap - (_layout.css) 
 * ------------------------------------------------------------------- */
.search-wrap {
	display: block;
	text-align: center;
	background: white;
	opacity: 0;
	visibility: hidden;
	z-index: 900;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.search-wrap .search-form {
	width: 100%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.search-wrap .search-form::after {
	content: "Press Enter to begin your search.";
	display: block;
	letter-spacing: 0.6px;
	font-size: 1.6rem;
	margin-top: 3rem;
	text-align: center;
	color: #999999;
}

.search-wrap .search-form ::-webkit-input-placeholder {
	color: #000;
	text-align: center !important;
	opacity: 1;
}

.search-wrap .search-form :-moz-placeholder {
	color: #000;
	text-align: center !important;
	opacity: 1;
}

.search-wrap .search-form ::-moz-placeholder {
	color: #000;
	text-align: center !important;
	opacity: 1;
}

.search-wrap .search-form :-ms-input-placeholder {
	color: #000;
	text-align: center !important;
	opacity: 1;
}

.search-wrap .search-form .placeholder {
	color: #000;
	text-align: center !important;
	opacity: 1;
}

.search-wrap input[type="search"] {
	background-color: transparent;
	border: none;
	color: #000;
	height: auto;
	width: 100%;
	font-family: "montserrat-bold", sans-serif;
	font-size: 6.3rem;
	line-height: 1.523;
	letter-spacing: 0.02rem;
	border-bottom: 1px solid #dedede;
	max-width: 680px;
	padding-top: .6rem !important;
	padding-bottom: .6rem !important;
	margin: 0 auto;
}

.search-wrap input[type="search"]:focus {
	outline: none;
}

.search-wrap .search-form input[type="submit"] {
	display: none;
}

.search-wrap .close-btn {
	position: fixed;
	display: block;
	width: 45px;
	height: 45px;
	top: 21px;
	left: 50%;
	margin-left: -23px;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
}

.search-wrap .close-btn::before,
.search-wrap .close-btn::after {
	content: '';
	position: absolute;
	display: inline-block;
	width: 2px;
	height: 20px;
	top: 12px;
	left: 22px;
	background-color: #333333;
}

.search-wrap .close-btn::before {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.search-wrap .close-btn::after {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.search-visible .search-wrap {
	opacity: 1;
	visibility: visible;
}

/* responsive:
 * search wrap
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	.search-wrap .search-form::after {
		font-size: 1.5rem;
	}

	.search-wrap input[type="search"] {
		max-width: none;
		width: 80%;
		font-size: 4.6rem;
	}

}
@media only screen and (max-width:600px) {
	.search-wrap input[type="search"] {
		font-size: 3.2rem;
	}

}
@media only screen and (max-width:400px) {
	.search-wrap input[type="search"] {
		font-size: 2.4rem;
	}

}


/* ===================================================================
 *  10. page header - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#page-header {
	padding: 4.2rem 0 0 0;
	text-align: center;
}

#page-header .row {
	max-width: 870px;
}

#page-header h1 {
	margin-bottom: 3rem;
}

#page-header p {
	color: #999999;
}


/* ===================================================================
 *  11. content wrap - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#content-wrap {
	margin-top: 6.6rem;
}

#content-wrap .row {
	max-width: 950px;
}

#content-wrap .row.wide {
	max-width: 1300px;
}

#content-wrap .row.narrow {
	max-width: 800px;
}

#content-wrap .content-media {
	position: relative;
	margin-bottom: 4.8rem;
}

#content-wrap .content-media img {
	vertical-align: bottom;
}

#content-wrap .primary-content {
	padding-left: 6rem;
	padding-right: 6rem;
}

#content-wrap .primary-content img {
	max-width: 910px;
	margin: 3rem 0 3rem -6rem;
}

#content-wrap .primary-content img.pull-right {
	margin: 0.9rem 0 0 2.4rem;
	max-width: 100%;
}

#content-wrap .primary-content img.pull-left {
	margin: 0.9rem 2.4rem 0 0;
	max-width: 100%;
}

#content-wrap .entry-title,
#content-wrap .page-title {
	font-size: 3.3rem;
	line-height: 1.364;
	margin-bottom: 0.9rem;
}

/* responsive:
 * content wrap
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1024px) {
	#content-wrap .row {
		max-width: 880px;
	}
	#content-wrap .primary-content {
		padding-left: 5rem;
		padding-right: 5rem;
	}
	#content-wrap .primary-content img {
		max-width: 844px;
		margin: 3rem 0 3rem -5rem;
	}

}

@media only screen and (max-width:960px) {
	#content-wrap .row {
		max-width: 880px;
	}
	#content-wrap .primary-content {
		padding-left: 4rem;
		padding-right: 4rem;
	}
	#content-wrap .primary-content img {
		max-width: 100%;
		margin: 3rem 0 3rem 0;
	}

}

@media only screen and (max-width:768px) {
	#content-wrap {
		margin-top: 4.8rem;
	}
	#content-wrap .primary-content {
		padding-left: 0;
		padding-right: 0;
	}
	#content-wrap .entry-title,
	#content-wrap .page-title {
		font-size: 3rem;
	}

}

@media only screen and (max-width:600px) {
	#content-wrap .entry-title,
	#content-wrap .page-title {
		font-size: 2.8rem;
	}

}


/* ===================================================================
 *  12. bricks masonry - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
#bricks {
	padding-top: 4.2rem;
}

#bricks .masonry {
	max-width: 1440px;
}

#bricks .pagination {
	margin-top: 6rem;
}

#bricks.with-top-sep {
	position: relative;
}

#bricks.with-top-sep::before {
	content: "";
	display: block;
	position: absolute;
	left: 50%;
	top: 0;
	margin-left: -100px;
	width: 200px;
	height: 1px;
	background-color: #D1D1D1;
}

.bricks-wrapper:before,
.bricks-wrapper:after {
	content: "";
	display: table;
}

.bricks-wrapper:after {
	clear: both;
}

.bricks-wrapper .grid-sizer,
.bricks-wrapper .brick {
	width: 25%;
}

.bricks-wrapper .brick {
	float: left;
	padding: 0 20px;
}

.bricks-wrapper .featured-grid {
	width: 50%;
}

.bricks-wrapper .featured-grid .entry-content {
	width: 100%;
	background: #151515;
}

/* responsive: 
/* bricks masonry
 * ------------------------------------------------------------------- */
@media only screen and (max-width:1280px) {
	.bricks-wrapper .grid-sizer,
	.bricks-wrapper .brick {
		width: 33.33333%;
	}

	.bricks-wrapper .featured-grid {
		width: 66.66667%;
	}
}
@media only screen and (max-width:1024px) {
	.bricks-wrapper .brick {
		padding: 0 18px;
	}

}
@media only screen and (max-width:960px) {
	.bricks-wrapper .grid-sizer,
	.bricks-wrapper .brick {
		width: 50%;
	}

	.bricks-wrapper .featured-grid {
		width: 100%;
	}
}
@media only screen and (max-width:768px) {
	.bricks-wrapper .brick {
		padding: 0 15px;
	}

}

@media only screen and (max-width:600px) {
	#bricks .row {
		max-width: 460px;
	}

	.bricks-wrapper .grid-sizer,
	.bricks-wrapper .brick {
		width: 100%;
		padding: 0 10px;
		clear: both;
	}
}
@media only screen and (max-width:400px) {
	.bricks-wrapper .brick {
		width: 100% !important;
		float: none !important;
		clear: both !important;
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}

}


/* ===================================================================
 *  13. footer - (_layout.scss)
 *
 * ------------------------------------------------------------------- */
footer {
	padding-top: 9rem;
	padding-bottom: 3rem;
	font-size: 1.5rem;
	color: #8E8E8E;
}
footer a,
footer a:visited {
	color: #000000;
}

footer [class*="col-"] {
	margin-bottom: 1.2rem;
}

footer h4 {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 1.2rem;
	margin-bottom: 1.5rem;
}

footer ul {
	margin-left: 0;
}

footer ul li {
	padding-left: 0;
}

.footer-main {
	padding-bottom: 1.5rem;
}

.footer-main ul li {
	list-style: none;
}

.footer-main ul a,
.footer-main ul a:visited {
	color: #8E8E8E;
	border: none;
}

.footer-main ul a:hover,
.footer-main ul a:focus {
	color: #000000;
}

.footer-main .footer-subscribe p {
	margin-bottom: 1.5rem;
}

.footer-main .footer-subscribe .subscribe-form #mc-form {
	padding: 0;
}

.footer-main .footer-subscribe .subscribe-form input[type="email"] {
	padding: .6rem 2rem;
	background: rgba(0, 0, 0, 0.1);
	border: none;
	width: 100%;
	color: #777777;
	margin-bottom: 1.8rem;
}

.footer-main .footer-subscribe .subscribe-form input[type="submit"] {
	display: none;
}

.footer-main .footer-subscribe .subscribe-form .subscribe-message {
	font-family: "roboto-bold", sans-serif;
	font-size: 1.4rem;
	color: #000000;
}

.footer-main .footer-subscribe .subscribe-form .subscribe-message i {
	color: #000000;
	margin-right: 5px;
}

.footer-bottom {
	margin-top: 3rem;
	text-align: center;
}

.footer-bottom .copyright span {
	display: inline-block;
}

.footer-bottom .copyright span::after {
	content: "|";
	display: inline-block;
	padding: 0 1rem 0 1.2rem;
	color: rgba(0, 0, 0, 0.2);
}

.footer-bottom .copyright span:last-child::after {
	display: none;
}

/* responsive:
 * footer
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	.footer-main .footer-info {
		text-align: center;
	}

}

@media only screen and (max-width:600px) {
	.footer-main .row {
		max-width: 420px;
	}

	.footer-main .footer-subscribe {
		text-align: center;
	}

	.footer-main .subscribe-form form input[type="email"] {
		margin-left: auto !important;
		margin-right: auto !important;
		text-align: center;
	}

	.footer-bottom {
		padding-bottom: .6rem;
	}

	.footer-bottom .copyright span {
		display: block;
	}

	.footer-bottom .copyright span::after {
		display: none;
	}

}

@media only screen and (max-width:400px) {
	.footer-main .site-links,
	.footer-main .social-links {
		text-align: center;
	}

	.footer-main .site-links h4,
	.footer-main .social-links h4 {
		border: none;
	}

	.footer-main ul {
		width: 100%;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.footer-main ul li a {
		display: block;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		padding: .6rem 0;
	}

	.footer-main ul li:first-child a {
		border-top: 1px solid rgba(0, 0, 0, 0.08);
	}

}

/* -------------------------------------------------------------------
 * go to top
 * ------------------------------------------------------------------- */
#go-top {
	position: fixed;
	bottom: 24px;
	right: 30px;
	z-index: 700;
	display: none;
}

#go-top a {
	text-decoration: none;
	border: 0 none;
	display: block;
	height: 63px;
	width: 60px;
	line-height: 63px;
	text-align: center;
	background: #b5b5b5;
	color: #555;
	text-align: center;
	text-transform: uppercase;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

#go-top a i {
	font-size: 24px;
	line-height: inherit;
}

#go-top a:visited {
	background: #b5b5b5;
	color: #555;
}

#go-top a:hover,
#go-top a:focus {
	background: #000000;
	color: #FFFFFF;
}

/* responsive:
 * go top
 * ------------------------------------------------------------------- */
@media only screen and (max-width:600px) {
	#go-top {
		bottom: 0;
		right: 0;
	}

}


/* ===================================================================
 *  14. blog styles
 *
 * ------------------------------------------------------------------- */

/* single post - (_blog.scss) 
 * ------------------------------------------------------------------- */
.blog-single .entry-meta {
	font-family: "muli-regular", sans-serif;
	font-size: 1.5rem;
	list-style: none;
	margin-left: 0;
	margin-bottom: 5.4rem;
	color: #999999;
}
.blog-single .entry-meta a,
.blog-single .entry-meta a:visited {
	color: #000000;
}

.blog-single .entry-meta li {
	display: inline-block;
	padding-left: 0;
}

.blog-single .entry-meta .date {
	margin-right: 1rem;
}

.blog-single .entry-meta .cat a {
	margin-right: 5px;
}

.blog-single .entry-meta .cat a:last-child {
	margin-right: 0;
}

.blog-single .entry-meta .cat a::after {
	content: ",";
}

.blog-single .entry-meta .cat a:last-child::after {
	content: none;
}

.blog-single .tags {
	margin: 6rem 0 4.2rem;
}

.blog-single .tags span {
	color: #151515;
	margin-right: 1rem;
	font-size: 1.5rem;
	font-family: "montserrat-bold", sans-serif;
}

.blog-single .tags a {
	color: #999999;
	margin-right: 1rem;
}

.blog-single .author-profile {
	margin-top: 9rem;
	padding-left: 9rem;
	position: relative;
}

.blog-single .author-profile a,
.blog-single .author-profile a:visited {
	color: #000000;
}

.blog-single .author-profile img {
	margin: 0.6rem 0 0 0 !important;
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
}

.blog-single .author-profile p {
	margin-bottom: 1.2rem;
}

.blog-single .author-profile .author-social {
	list-style: none;
	margin-left: 0;
	font-size: 1.4rem;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
}

.blog-single .author-profile .author-social li {
	display: inline-block;
	margin-right: 0.8rem;
	padding-left: 0;
}

.blog-single .author-profile .author-social a {
	color: #999999;
}

.blog-single .pagenav {
	padding-top: 1.5rem;
	margin: 12rem 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-single .pagenav div {
	padding-top: 1.5rem;
	float: left;
	width: 50%;
}

.blog-single .pagenav a {
	font-size: 2.1rem;
	line-height: 1.571;
	font-family: "montserrat-bold", sans-serif;
	border: none;
	color: #000000;
}

.blog-single .pagenav a span {
	font-size: 1.5rem;
	line-height: 3rem;
	font-family: "muli-regular", sans-serif;
	display: block;
	margin-bottom: 1.5rem;
	color: #999999;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.blog-single .pagenav a:hover span {
	color: #000000;
}

/* responsive:
 * single post
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	.blog-single .entry-meta {
		font-size: 1.5rem;
	}

	.blog-single .pagenav div {
		float: none;
		width: 100%;
	}

	.blog-single .pagenav div:first-child {
		margin-bottom: 0.9rem;
	}

}

@media only screen and (max-width:600px) {
	.blog-single .author-profile {
		padding-left: 0;
		text-align: center;
	}

	.blog-single .author-profile img {
		position: static;
		width: 6.6rem;
		height: 6.6rem;
		margin: 0 0 0.6rem 0 !important;
	}

	.blog-single .pagenav {
		text-align: center;
	}

}


/* comments - (_blog.scss) 
 * ------------------------------------------------------------------- */
.comments-wrap {
	background-color: rgba(0, 0, 0, 0.08);
	padding: 7.8rem 0 9rem;
}

/* comments
 * -------------------------------------------------- */
#comments {
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
}

#comments h3 {
	margin-bottom: 1.5rem;
}

#comments ol,
#comments ul {
	list-style: none;
}

.commentlist {
	margin: 3rem 0 5.4rem;
	padding: 0;
}

.commentlist > li {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 1.8rem 0;
	padding-left: 14%;
}

.commentlist li .avatar {
	position: absolute;
	left: 0;
	display: block;
	height: 6rem;
	width: 6rem;
}

.commentlist li .avatar img {
	margin-top: 0.6rem;
	height: 6rem;
	width: 6rem;
	border-radius: 100%;
}

.commentlist li .comment-info cite {
	font-family: "montserrat-bold", sans-serif;
	font-size: 1.8rem;
	font-style: normal;
	color: #151515;
}

.commentlist li .comment-info .comment-meta {
	font-family: "muli-regular", sans-serif;
	font-size: 1.4rem;
	line-height: 3rem;
	display: block;
	color: #888888;
}

.commentlist li .comment-info .comment-meta .reply {
	font-family: "montserrat-bold", sans-serif;
	color: #151515;
}

.commentlist li .comment-info .comment-meta .sep {
	margin: 0 5px;
}

.commentlist li .comment-text {
	clear: both;
	margin: 1.8rem 0 0 0;
	padding: 0;
}

.commentlist li ul.children {
	margin: 0;
	padding: 1.8rem 0 0 0;
}

.commentlist li ul.children li {
	padding-left: 5%;
	padding-top: 1.8rem;
}

/* comments form
 * ---------------------------- */
#comments .respond {
	padding-top: 4.2rem;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#comments form {
	padding-top: 3rem;
}

#comments form .form-field {
	margin-bottom: 3.9rem;
}

/* responsive:
 * comments
 * ------------------------------------------------------------------- */
@media only screen and (max-width:768px) {
	#comments form button {
		width: 100%;
	}

}

@media only screen and (max-width:600px) {
	#comments h3 {
		text-align: center;
	}

	.commentlist > li {
		padding-left: 0;
	}

	.commentlist li .avatar {
		display: none;
	}

}

 
/* post format gallery (_blog.scss)
 * ------------------------------------------------------------------- */
.format-gallery .post-slider {
	position: relative;
	cursor: pointer;
}

.format-gallery .flex-control-paging {
	position: absolute;
	bottom: 2.1rem;
	right: 2rem;
	margin-left: 0;
	z-index: 500;
}

.format-gallery .flex-control-paging li {
	float: left;
	cursor: pointer;
	margin: 0 3px;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
}

.format-gallery .flex-control-paging li a {
	background-color: #ffffff;
	border-radius: 50%;
	display: block;
	height: 10px;
	width: 10px;
	opacity: .6;
	box-shadow: 0 1 3px rgba(0, 0, 0, 0.5);
}

.format-gallery .flex-control-paging li a:hover,
.format-gallery .flex-control-paging li a.flex-active {
	opacity: 1;
}

 
/* post format audio (_blog.scss)
 * ------------------------------------------------------------------- */
.format-audio .audio-wrap {
	position: absolute !important;
	bottom: 0;
	left: 0;
	width: 100%;
}

.format-audio .mejs-controls,
.format-audio .mejs-mediaelement,
.format-audio .mejs-container {
	background-color: rgba(17, 17, 17, 0.5) !important;
	height: 42px;
}

.format-audio .mejs-container {
	z-index: 500;
	max-width: 100%;
}

.format-audio .mejs-container .mejs-controls {
	padding: 0 10px;
	z-index: 2;
}

.format-audio .mejs-container .mejs-controls button {
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-webkit-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
	height: 42px;
	margin: 0 6px;
}

.format-audio .mejs-container .mejs-controls .mejs-time {
	line-height: 42px;
	height: 42px;
	padding-top: 0;
}

.format-audio .mejs-controls .mejs-button {
	height: 42px;
}

.format-audio .mejs-controls .mejs-button button,



.format-audio .mejs-controls .mejs-button button:focus {
	outline: none;
}

.format-audio .mejs-controls div.mejs-time-rail {
	margin: 19px 6px 0;
	padding: 0;
	height: 3px;
	top: 19px;
}

.format-audio .mejs-controls .mejs-time-rail span,
.format-audio .mejs-controls .mejs-time-rail a {
	border-radius: 0;
	margin: 0;
}

.format-audio .mejs-controls .mejs-time-rail .mejs-time-total {
	position: relative;
	background: #333;
	height: 3px;
}

.format-audio .mejs-controls .mejs-time-rail .mejs-time-loaded {
	background: #7A7A7A;
	height: 3px;
}

.format-audio .mejs-controls .mejs-time-rail .mejs-time-current {
	background: #fff;
	height: 3px;
}

.format-audio .mejs-controls .mejs-time-rail .mejs-time-float {
	border: none;
	height: 18px;
	line-height: 18px;
	border-radius: 2px;
}

.format-audio .mejs-controls .mejs-time-rail .mejs-time-float-current {
	padding: 0 4px;
	width: auto;
}

.format-audio .mejs-controls .mejs-time-rail .mejs-time-float-corner {
	top: 16px;
}

.format-audio .mejs-controls a.mejs-horizontal-volume-slider {
	height: 42px;
}

.format-audio .mejs-controls .mejs-volume-button {
	position: relative;
	margin-right: 6px;
}

.format-audio .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
	border-radius: 0;
	background: #333;
	height: 3px;
	top: 19px;
}

.format-audio .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
	border-radius: 0;
	background: #fff;
	height: 3px;
	top: 19px;
}


/* ===================================================================
 *  15. page styles
 *
 * ------------------------------------------------------------------- */
 
/* contact page
 * ------------------------------------------------------------------- */

/* map
 * ----------------------------------------------- */
#map-wrap {
	background: white;
	z-index: 800;
	height: 420px;
}

#map-wrap .gm-style img {
	max-width: none;
}

#map-wrap .gm-style label {
	width: auto;
	display: inline;
}

#map-wrap #map-container {
	height: 100%;
	width: 100%;
	background: #DBDBDB;
	position: relative;
}

#map-wrap #map-zoom-in,
#map-wrap #map-zoom-out {
	height: 3.2rem;
	width: 3.2rem;
	cursor: pointer;
	margin-right: 4rem;
	color: white;
	background-color: black;
	display: none;
}

@media only screen and (max-width:600px) {
	#map-wrap #map-zoom-in,
	#map-wrap #map-zoom-out {
		margin-right: 3.5rem;
	}

}

@media only screen and (max-width:400px) {
	#map-wrap #map-zoom-in,
	#map-wrap #map-zoom-out {
		margin-right: 2.5rem;
	}

}

#map-wrap #map-zoom-in {
	margin-bottom: 2px;
	margin-top: 30px;
}

#map-wrap #map-zoom-in::before,
#map-wrap #map-zoom-out::before {
	display: block;
	color: white;
	text-align: center;
	font-family: 'FontAwesome';
	font-size: 14px;
	content: "\f067";
	height: 32px;
	width: 32px;
	line-height: 32px;
	-moz-transition: color 0.3s ease-in-out;
	-o-transition: color 0.3s ease-in-out;
	-webkit-transition: color 0.3s ease-in-out;
	-ms-transition: color 0.3s ease-in-out;
	transition: color 0.3s ease-in-out;
}

#map-wrap #map-zoom-in::before {
	content: "\f067";
}

#map-wrap #map-zoom-out::before {
	content: "\f068";
}

#map-wrap #map-zoom-in:hover::before,
#map-wrap #map-zoom-out:hover::before {
	color: #034e4a;
}

/* contact form
 * ----------------------------------------------- */
#cForm {
	margin-top: 3.6rem;
}